import {request} from './request'

export function checkLogin(data) {
  return request({
    method: 'post',
    url: '/check-login',
    data,
  })
}

export function getRawList(data) {
  return request({
    method: 'post',
    url: '/raw-info-list',
    data,
  })
}

export function addRawInfo(data) {
  return request({
    method: 'post',
    url: '/raw-info-add',
    data,
  })
}

export function editRawInfo(data) {
  return request({
    method: 'post',
    url: '/raw-info-edit',
    data,
  })
}

export function getRawCate(data) {
  return request({
    method: 'post',
    url: '/raw-cate',
    data,
  })
}

export function getRawSKUList(data) {
  return request({
    method: 'post',
    url: '/raw-sku-list',
    data,
  })
}

export function getRawSKUNames(data) {
  return request({
    method: 'post',
    url: '/raw-sku-names',
    data,
  })
}

export function getRawSKUSupps(data) {
  return request({
    method: 'post',
    url: '/raw-sku-supps',
    data,
  })
}

export function addRawSKU(data) {
  return request({
    method: 'post',
    url: '/raw-sku-add',
    data,
  })
}

export function rawRuku(data) {
  return request({
    method: 'post',
    url: '/raw-ruku',
    data,
  })
}

export function rawGetGys(data) {
  return request({
    method: 'post',
    url: '/raw-get-gys',
    data,
  })
}

export function rawRukuDo(data) {
  return request({
    method: 'post',
    url: '/raw-ruku-do',
    data,
  })
}

export function rawChukuDo(data) {
  return request({
    method: 'post',
    url: '/raw-chuku-do',
    data,
  })
}

export function getRawInLogs(data) {
  return request({
    method: 'post',
    url: '/raw-in-logs',
    data,
  })
}

export function getRawOutLogs(data) {
  return request({
    method: 'post',
    url: '/raw-out-logs',
    data,
  })
}

export function getCateName(data) {
  return request({
    method: 'post',
    url: '/raw-get-cate-by-name',
    data,
  })
}

export function insSquareSKU(data) {
  return request({
    method: 'post',
    url: '/square-sku-ins',
    data,
  })
}

export function clSquareSKU(data) {
  return request({
    method: 'post',
    url: '/square-sku-ins-cl',
    data,
  })
}

export function getSquareSKU(data) {
  return request({
    method: 'post',
    url: '/square-get-sku',
    data,
  })
}

export function delSquareBatFile(data) {
  return request({
    method: 'post',
    url: '/square-del-bat-file',
    data,
  })
}

export function batEditSquare(data) {
  return request({
    method: 'post',
    url: '/square-do-edit-bat',
    data,
  })
}

export function singleEditSquare(data) {
  return request({
    method: 'post',
    url: '/square-do-edit-single',
    data,
  })
}

export function getSquareLogs(data) {
  return request({
    method: 'post',
    url: '/square-get-logs',
    data,
  })
}

export function getCustom(data) {
  return request({
    method: 'post',
    url: '/custom-get-list',
    data,
  })
}

export function addCustomInfo(data) {
  return request({
    method: 'post',
    url: '/custom-add',
    data,
  })
}

export function editCustomInfo(data) {
  return request({
    method: 'post',
    url: '/custom-edit',
    data,
  })
}

export function getSourceList(data) {
  return request({
    method: 'post',
    url: '/source-get-list',
    data,
  })
}

export function getBSSquare(data) {
  return request({
    method: 'post',
    url: '/square-get-bs',
    data,
  })
}

export function editBSSquare(data) {
  return request({
    method: 'post',
    url: '/square-edit-bs',
    data,
  })
}

export function getSquarePv(data) {
  return request({
    method: 'post',
    url: '/square-get-pv',
    data,
  })
}

export function getCacheSquare(data) {
  return request({
    method: 'post',
    url: '/square-get-cache',
    data,
  })
}

export function getBatData(data) {
  return request({
    method: 'post',
    url: '/square-get-bat',
    data,
  })
}

export function rewriteSquare(data) {
  return request({
    method: 'post',
    url: '/square-rw-do',
    data,
  })
}

export function rewriteSquareCl(data) {
  return request({
    method: 'post',
    url: '/square-rw-cl',
    data,
  })
}

export function addUser(data) {
  return request({
    method: 'post',
    url: '/user-add',
    data,
  })
}

export function getUsers(data) {
  return request({
    method: 'post',
    url: '/user-get-list',
    data,
  })
}

export function deleteUsers(data) {
  return request({
    method: 'post',
    url: '/user-delete',
    data,
  })
}

export function editUsers(data) {
  return request({
    method: 'post',
    url: '/user-edit',
    data,
  })
}

export function getLoginHistory(data) {
  return request({
    method: 'post',
    url: '/user-get-login-logs',
    data,
  })
}

export function editMyPassword(data) {
  return request({
    method: 'post',
    url: '/user-edit-my-pwd',
    data,
  })
}

export function delSquareSKU(data) {
  return request({
    method: 'post',
    url: '/square-sku-del',
    data,
  })
}

export function getUserName(data) {
  return request({
    method: 'post',
    url: '/user-get-name',
    data,
  })
}

export function getExSkuData(data) {
  return request({
    method: 'post',
    url: '/ex-get-sku',
    data,
  })
}

export function getExCache(data) {
  return request({
    method: 'post',
    url: '/ex-get-cache',
    data,
  })
}

export function exDoIns(data) {
  return request({
    method: 'post',
    url: '/ex-do-ins',
    data,
  })
}

export function cancelExIns(data) {
  return request({
    method: 'post',
    url: '/ex-ins-cl',
    data,
  })
}

export function exRewriteDo(data) {
  return request({
    method: 'post',
    url: '/ex-rw-do',
    data,
  })
}

export function exRewriteCl(data) {
  return request({
    method: 'post',
    url: '/ex-rw-cl',
    data,
  })
}

export function exGetBat(data) {
  return request({
    method: 'post',
    url: '/ex-get-bat',
    data,
  })
}

export function exBatDo(data) {
  return request({
    method: 'post',
    url: '/ex-bat-do',
    data,
  })
}

export function exBatCl(data) {
  return request({
    method: 'post',
    url: '/ex-bat-cl',
    data,
  })
}

export function exGetBs(data) {
  return request({
    method: 'post',
    url: '/ex-get-bs',
    data,
  })
}

export function exBsDo(data) {
  return request({
    method: 'post',
    url: '/ex-bs-do',
    data,
  })
}

export function exBsDel(data) {
  return request({
    method: 'post',
    url: '/ex-bs-del',
    data,
  })
}

export function exEditRow(data) {
  return request({
    method: 'post',
    url: '/ex-row-edit',
    data,
  })
}

export function getExLogs(data) {
  return request({
    method: 'post',
    url: '/ex-get-logs',
    data,
  })
}

export function getSqLogs(data) {
  return request({
    method: 'post',
    url: '/square-log-list',
    data,
  })
}

export function addSF(data) {
  return request({
    method: 'post',
    url: '/source-add',
    data,
  })
}

export function editSF(data) {
  return request({
    method: 'post',
    url: '/source-edit',
    data,
  })
}

export function delSF(data) {
  return request({
    method: 'post',
    url: '/source-del',
    data,
  })
}

export function getRoundSku(data) {
  return request({
    method: 'post',
    url: '/round-get-sku',
    data,
  })
}

export function roundCancel(data) {
  return request({
    method: 'post',
    url: '/round-cl',
    data,
  })
}

export function inRound(data) {
  return request({
    method: 'post',
    url: '/round-in-do',
    data,
  })
}

export function getRoundCache(data) {
  return request({
    method: 'post',
    url: '/round-get-cache',
    data,
  })
}

export function rwRound(data) {
  return request({
    method: 'post',
    url: '/round-rw-do',
    data,
  })
}

export function roundBat(data) {
  return request({
    method: 'post',
    url: '/round-bat-edit-do',
    data,
  })
}

export function roundBatP(data) {
  return request({
    method: 'post',
    url: '/round-bat-edit',
    data,
  })
}

export function roundGetBs(data) {
  return request({
    method: 'post',
    url: '/round-bs-show',
    data,
  })
}

export function roundBsEdit(data) {
  return request({
    method: 'post',
    url: '/round-bs-edit',
    data,
  })
}

export function roundDel(data) {
  return request({
    method: 'post',
    url: '/round-bs-del',
    data,
  })
}

export function roundOneEdit(data) {
  return request({
    method: 'post',
    url: '/round-id-edit',
    data,
  })
}

export function getRoundLogs(data) {
  return request({
    method: 'post',
    url: '/round-get-log',
    data,
  })
}

export function csvIntoCl(data) {
  return request({
    method: 'post',
    url: '/square-plugin-csv-cl',
    data,
  })
}

export function csvIntoGet(data) {
  return request({
    method: 'post',
    url: '/square-plugin-csv-get-cache',
    data,
  })
}

export function csvIntoDo(data) {
  return request({
    method: 'post',
    url: '/square-plugin-csv-do',
    data,
  })
}

export function squareScanId(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-id',
    data,
  })
}

export function squareScanGetCache(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-get-cache',
    data,
  })
}

export function squareScanSaveDo(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-save-do',
    data,
  })
}

export function squareScanEdit(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-edit',
    data,
  })
}

export function squareScanClear(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-clear',
    data,
  })
}

export function squareScanSubForm(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-sub-form',
    data,
  })
}

export function squareGetSourceData(data) {
  return request({
    method: 'post',
    url: '/square-plugin-get-source-data',
    data,
  })
}

export function squareClearSourceData(data) {
  return request({
    method: 'post',
    url: '/square-plugin-clear-source-data',
    data,
  })
}

export function squareXlsxIntoDo(data) {
  return request({
    method: 'post',
    url: '/square-plugin-xls-do',
    data,
  })
}

export function squareXlsxGetCache(data) {
  return request({
    method: 'post',
    url: '/square-plugin-xls-get-cache',
    data,
  })
}

export function squareSoWaferID(data) {
  return request({
    method: 'post',
    url: '/square-plugin-so-wid',
    data,
  })
}

export function squareSoClear(data) {
  return request({
    method: 'post',
    url: '/square-plugin-so-clear',
    data,
  })
}

export function squareSoUseWhich(data) {
  return request({
    method: 'post',
    url: '/square-plugin-so-which',
    data,
  })
}

export function squareSoGetCache(data) {
  return request({
    method: 'post',
    url: '/square-plugin-so-get-cache',
    data,
  })
}

export function squareSoSubForm(data) {
  return request({
    method: 'post',
    url: '/square-plugin-so-sub-form',
    data,
  })
}

export function rawInLogEdit(data) {
  return request({
    method: 'post',
    url: '/raw-in-log-edit',
    data,
  })
}

export function rawOutLogEdit(data) {
  return request({
    method: 'post',
    url: '/raw-out-log-edit',
    data,
  })
}

export function squareScanDelRow(data) {
  return request({
    method: 'post',
    url: '/square-plugin-scan-del',
    data,
  })
}

export function squareGetPvData(data) {
  return request({
    method: 'post',
    url: '/square-get-pv-data',
    data,
  })
}

export function rawBackSkuDo(data) {
  return request({
    method: 'post',
    url: '/raw-back-sku-do',
    data,
  })
}

export function getRawCodeList(data) {
  return request({
    method: 'post',
    url: '/raw-get-code-list',
    data,
  })
}

export function getEpiInList(data) {
  return request({
    method: 'post',
    url: '/ex-in-list',
    data,
  })
}

export function getEpiOutList(data) {
  return request({
    method: 'post',
    url: '/ex-out-list',
    data,
  })
}

export function getEpiInInfo(data) {
  return request({
    method: 'post',
    url: '/ex-in-info',
    data,
  })
}

export function getEpiOutInfo(data) {
  return request({
    method: 'post',
    url: '/ex-out-info',
    data,
  })
}

export function getSmtSKU(data) {
  return request({
    method: 'post',
    url: '/square-plugin-smt-sku',
    data,
  })
}

export function getEpiInToday(data) {
  return request({
    method: 'post',
    url: '/ex-in-today',
    data,
  })
}

export function getEpiOutToday(data) {
  return request({
    method: 'post',
    url: '/ex-out-today',
    data,
  })
}

export function getEpiIsIn(data) {
  return request({
    method: 'post',
    url: '/ex-is-in',
    data,
  })
}

export function getEpiMake(data) {
  return request({
    method: 'post',
    url: '/ex-make-list',
    data,
  })
}

export function getEpiMakeInfo(data) {
  return request({
    method: 'post',
    url: '/ex-make-info',
    data,
  })
}

export function getEpiMakeToday(data) {
  return request({
    method: 'post',
    url: '/ex-make-today',
    data,
  })
}

export function insBuyEpi(data) {
  return request({
    method: 'post',
    url: '/epi-ib-in',
    data,
  })
}

export function getEpiSub(data) {
  return request({
    method: 'post',
    url: '/epi-sub-get',
    data,
  })
}

export function insEpiSub(data) {
  return request({
    method: 'post',
    url: '/epi-sub-ins',
    data,
  })
}

export function getConfirmSub(data) {
  return request({
    method: 'post',
    url: '/epi-sub-confirm',
    data,
  })
}

export function doDelEpiSub(data) {
  return request({
    method: 'post',
    url: '/epi-sub-del',
    data,
  })
}
export function getEpiSubGIO(data) {
  return request({
    method: 'post',
    url: '/epi-sub-get-gio',
    data,
  })
}
